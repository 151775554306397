import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {
  Box,
  Button,
  Checkbox,
  OutlinedInput,
  Typography,
  InputAdornment,
  IconButton,
  Hidden,
  Container
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { login,ellipse } from "./assets";
import { Horizontalinventohub } from "../../landingpage/src/assets";

const heading = "Signin";


const styles = {

  overflowStyle:{
    overflow:"hidden"
  },
  image: {
    height: "920px",
    position:"absolute" as "absolute",
    right : 0,
    "@media (max-width: 700px)": {
      width: "",
      height: "",
    },
  },
  heading: {
    fontSize: "36px",
    fontWeight: 700,
    color: "#000000",
    marginTop: "40px",
    marginBottom: "30px",
    fontFamily: "'Inter', sans-serif"
  },
  subHeading: {
    fontSize: "20px",
    fontWeight: 500,
    marginBottom: "31px",
    color: "#6F6E6E",
    fontFamily: "'Inter', sans-serif"
  },
  headingSign: {
    fontSize: "32px",
    fontWeight: 700,
    color: "#364BA0",
    marginBottom: "31px",
    fontFamily: "'Inter', sans-serif"
  },
  headingSign2: {
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: 700,
    color: "#206FC4",
    fontFamily: "'Inter', sans-serif"
  },
  formStyle2: {
    maxWidth: "432px",
  },

  textField: {
    width: "100%",
    "&.MuiFormLabel-root.Mui-focused ": {
      color: "#6F6E6E !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ": {
      borderColor: "#6F6E6E !important",
    },
  },
  boxstyle: {
    marginBottom: "27px",
  },
  signinButton: {
    marginBottom: "24px",
    height: "55px",
    backgroundColor: "#206FC4",
    color: "#FFFFFF",
    width: "100%",
    fontSize: "16px",
    fontWeight: 700,

  },
  boxstyle2: {
    display: "flex",
    marginTop: "19px",
    width: "100%",
    marginBottom: "31px",
    alignItems: "center",
    justifyContent: "space-between",
    "@media(max-width:600px)": {
      display: "block",
    },
  },
  typography: {
    display: "block",
    color: "#848484",
    cursor:"pointer",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
  },
  labelStyle: {
    color: "#585858",
    marginBottom: "12px",
    fontSize: "20px",
    fontWeight: 400,
    fontFamily: "'Inter', sans-serif",
  },
  maingrid: {
    width:"100%",
    "@media(max-width:600px)": {}
  },
  signUpLink:{
    color: "#364BA0",
    cursor:"pointer",
    fontFamily: "'Inter', sans-serif",
    fontWeight: 700,
    borderBottom: "2px solid",
  },
  formError:{
    color:'red',
    fontSize:14,
    fontFamily: "'Inter', sans-serif",
  },
  ellipseImg:{
    position:'absolute' as 'absolute',
    left:'0',
    width:'240px'
  },
  invento:{
    width:"300px",
    height:"61px",
    objectFit:"cover",
     marginTop: "50px",
     marginLeft:"10px",
     marginBottom: "45px",
     cursor: "pointer"
  },
};

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
      // Customizable Area Start
      const signinButtonStyle = {
        marginBottom: "24px",
        height: "55px",
        backgroundColor: this.state.isHovered ? " #004de6" : "#364BA0",
        color: "#FFFFFF",
        width: "100%",
        fontSize: "16px",
        fontWeight: 700,
        textTransform: "none" as "none",
        "::first-letter": {
          textTransform: "uppercase"
        }
      };
  
      // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <Box style={styles.overflowStyle}>
        <Container>
          <Grid container style={styles.maingrid}>
            <Grid item xs={12} md={6} sm={6}>
            <img src={Horizontalinventohub} style={styles.invento as React.CSSProperties} onClick={this.handleLogoClick} data-test-id="handleLogoClick"/>
              <Typography style={styles.headingSign}>{heading}</Typography>
              <Box style={styles.formStyle2}>
                <Box style={styles.boxstyle}>
                  <Typography style={styles.labelStyle}>Email:</Typography>
                  <FormControl style={styles.textField} variant="outlined">
                    <OutlinedInput
                      data-test-id="txtInputEmail"
                      id="outlined-adornment-email"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "Email",
                        style: {
                          fontSize: "16px",
                          fontWeight: 500,
                          fontFamily: "'Inter', sans-serif",
                          color: "#000000",
                        },
                      }}
                      value={this.state.email} 
                      onChange={(e) => this.handleEmailNameChange(e)}
                    />
                  </FormControl>
                  {this.state.emailErr && (
                    <div style={styles.formError}>{this.state.emailErr}</div>
                  )}
                </Box>
                <Box style={styles.boxstyle}>
                  <Typography style={styles.labelStyle}>Password:</Typography>
                  <FormControl style={styles.textField} variant="outlined">
                  <OutlinedInput
                    data-test-id="txtInputPassword"
                    id="outlined-adornment-password"
                    type={this.state.showPassword ? "text" : "password"} 
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "password",
                      style: {
                        fontSize: "16px",
                        fontWeight: 500,
                        fontFamily: "'Inter', sans-serif",
                        color: "#000000",
                      },
                    }}
                    onPaste={(e) => e.preventDefault()} 
                    value={this.state.password} 
                    onChange={(e) => this.handlePasswordChange(e)} 
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.togglePasswordVisibility}
                          edge="end"
                        >
                          {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />

                  </FormControl>
                  {this.state.passwordErr && (
                    <div style={styles.formError}>{this.state.passwordErr}</div>
                  )}
                </Box>

                <Box style={styles.boxstyle2}>
                  <Typography style={styles.typography}>
                    <Checkbox
                      data-test-id={"btnRememberMe"}
                      color="default"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      onClick={(e)=>this.handleRememberMeChange(e)}
                    />
                    Remember Password
                  </Typography>
                  <Typography
                    data-test-id={"btnForgotPassword"}
                    style={styles.typography}
                    onClick={this.handleForgotPasswordClick} 
                  >
                    Forgot Password?
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  data-test-id={"btnEmailLogIn"}
                  id="emailButton"
                  style={signinButtonStyle}
                  onClick={this.loginAPI}
                  onMouseEnter={() => this.setState({isHovered:true})}
                  onMouseLeave={() => this.setState({isHovered:false})}

                >
                  Sign in
                </Button>
                <Typography  style={styles.subHeading}>
                  Don't have an account?{" "}
                    <span style={styles.signUpLink} data-test-id="btnSocialLogin" onClick={this.handleGoToSocialLogin} >Signup</span>
                </Typography>
              </Box>
            </Grid>
            <Hidden mdDown>
            <Grid item xs={12} md={6} sm={6}>
              <img src={login} style={styles.image} />
            </Grid>
            </Hidden>
          </Grid>
        </Container>
        <img src={ellipse} alt="ellipse" style={styles.ellipseImg}/>
      </Box>
      // Customizable Area End
    );
  }
}

