import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { ChangeEvent } from "react";
import storage from '../../../framework/src/StorageProvider';

// Customizable Area End

export const configJSON = require('./config');


export interface Props {
  navigation: any;
  // Customizable Area Start
  onStepChange: (stepChange: number) => void
  getNameSet?: ((event: any) => void),
  getSkillsSet?: ((event: any) => void),
  getEstimateSet?: ((value: any) => void),
  getCategoryIdSet?: ((value: any) => void),
  getPerDayChargeSet?: ((value: number) => void),
  getBudgetSet?: ((value: any) => void),
  getDescriptionSet?: ((value: string) => void),
  getRoadMap?: (() => void),
  getDurationSet?:((value:any)=>void),
  skillsOption?: any,
  skillValue?: string,
  name?:string,
  handleSkillMenuInputChange?: ((_: any, value: any) => void)
  classes?: any,
  handleFileChange?:any
  file?:any;
  skillLabel?:any;
  describeError?:any;
  estimate?:any
  description?: string; 
  duration?:any
  categoriesInfo?:any[];
  currentSelectedCatItem:any[];
  currentSelectedCatItemdata:any[]
  

  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  estimate:string;
  activeTabValue: number;
  selectedFile: File | any;
  skillLable:any
  error:boolean
  skillError:boolean
  firstName:any
  isHovered: boolean
  checkbox1Checked: boolean,
  checkbox2Checked: boolean,
  checkbox3Checked: boolean,
  checkbox4Checked: boolean,
  checkbox5Checked: boolean,
  checkbox6Checked: boolean,
  jobTitle: string, 
  anchorEl: null,
  categoriesInfo?:any[];
  currentSelectedCatItem:any[];
  authToken:string;
  userID:number |null;
  allSelected: boolean, 
  selectedItems: {},
  name: string;
 
  selectAll: boolean;
  select_id: number | null;
  selectedSubCategories: any;
  do_It_Later:boolean;


 



    // Customizable Area End
}
interface SS { }

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  apiSearchSkillDataId: string="";
  chooseCategoryAPIid: any;
  apipatentsdataID: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];

    this.state = {
      userID: null,
      authToken:"",
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      estimate:"large",
      activeTabValue: 0,
      selectedFile: null,
      skillLable:[],
      error:false,
      skillError:false,
      firstName: localStorage.getItem("accountHolderName"),
      isHovered:false,
      checkbox1Checked: true,
      checkbox2Checked: false,
      checkbox3Checked: false,
      checkbox4Checked: false,
      checkbox5Checked: false,
      checkbox6Checked: false,
      jobTitle: "", 
      anchorEl: null,
  categoriesInfo:[],
  currentSelectedCatItem:[],
  allSelected: false, 
  selectedItems: {},
  name: '',
 
  selectAll: false,
  select_id: 0,
  
  selectedSubCategories: {} as { [key: number]: number[] },
  do_It_Later:false


    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.setState({
      userID: await storage.get('accountId'),
      authToken: await storage.get('authToken'),
    });
    this.getChooseCategoryRes();
    
    // Customizable Area End
  }



  handleCategoryResp =(responseJson:any) => {
    this.setState({categoriesInfo:responseJson?.data})

  }
  getChooseCategoryRes = () => {
    const header = {
      "Content-Type": "application/json",
      "token": this.state.authToken

    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.chooseCategoryAPIid = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/categories`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  



  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog('Message Recived', message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.chooseCategoryAPIid) {
        this.handleCategoryResp(responseJson);
      }
      if (apiRequestCallId === this.apipatentsdataID) {
     console.log(responseJson);
     
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleRedirect = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "SearchTalent");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  
  
   handleAddBillingMethod2 = () => {
    this.props.navigation.navigate();
  };
  handleBack2 = () => {
    this.props.navigation.navigate();
  };
  handleAddBillingMethod = () => {
    this.props.navigation.navigate("CustomizedSteppers");
  };
  handleBack = () => {
    this.props.onStepChange(-1)
  };

  handleTitleBack = () => {
    this.props.onStepChange(-1)
  };
 
  handleTitleContinue = () => {
    if(this.props.name==""){
      this.setState({error:true})
      }
      else{
  
        this.props.onStepChange(+1)
      }
  };
  
  handleWorkScopeBack = () => {
    this.props.onStepChange(-1)
  };
  handleWorkScopeContinue = () => {
    this.props.onStepChange(1)
  };
  handleChooseCategoryBack = () => {
    this.props.onStepChange(-1)
  };
  handleApply = () => {
    this.props.onStepChange(1)
  }
  handleConversationBack = () => {
    this.props.onStepChange(-1)
  }
  handlePostThisJob = () => {
    this.props.navigation.navigate("ViewJob")
  }
 
  handleTabsChange = (event: any, newValue: any) => {
    this.setState({ activeTabValue: newValue });
  };


  
  
  handleClear = ()=>{
    this.setState({
      checkbox1Checked: false,
      checkbox2Checked: false,
      checkbox3Checked: false,
      checkbox4Checked: false,
      checkbox5Checked: false,
      checkbox6Checked: false
    });
  }
  handleHover = () => {
    this.setState({ isHovered: true });
  };
  
  handleLeave = () => {
    this.setState({ isHovered: false });
  };

  handlebackpage(){
    this.props.navigation.navigate("ExpertiseAndFeesForm");

  }
  handleNext(){

    this.handlePatentsPatchApi()
    this.props.navigation.navigate("AchievementsCustomForm");


  }
  doItLater(){
    this.setState({do_It_Later:true})
  }




  handleCheckboxChange = (e: any, parentId: number, subCategoryIds: number[]) => {
    const { checked } = e.target;
  
    this.setState((prevState) => {
      if (checked) {
        return {
          selectedSubCategories: {
            ...prevState.selectedSubCategories,
            [parentId]: subCategoryIds, 
          },
        };
      } else {
        
        return {
          selectedSubCategories: {
            ...prevState.selectedSubCategories,
            [parentId]: [], 
          },
        };
      }
    }, );
  };
  handlePatentsPatchApi = () => {
    const header = {
      redirect: 'follow',
      "token": this.state.authToken,
    };
  
    const selectedIds = Object.values(this.state.selectedSubCategories).flat();
  
    const formdata = new FormData();
    
    selectedIds.forEach((id: any) => {
      formdata.append("account[sub_category_sub_ids][]", id.toString());
    });
  
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apipatentsdataID = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/freelancers/${this.state.userID}/freelancer_profile_patents`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return true;
  };
  
  
  
  // Customizable Area End
}
