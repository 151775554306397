export const second_steper = require("../assets/second_steper.png");
export const thirdStep = require("../assets/thirdStep.png");
export const AvatarImg = require('../assets/Group.png');
export const firstStep = require('../assets/firstStep.png');
export const addBtn = require('../assets/addBtn.png')
export const forthstep = require('../assets/forthstep.png')
export const image_Success = require("../assets/image_Success.png");
export const remind_icon = require("../assets/remind_icon.png");
export const fifth_steper = require('../assets/fifth_steper.png')


