import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import { ChangeEvent } from "react";
import storage from 'framework/src/StorageProvider';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
interface ProfessionalDetails {}

interface WorkExperience {}

interface Education {}

interface Certificate {}
  interface SocialMediaForm {
    Media_Name: string;
    id: string;
    Media_Link: string;
   
  }

  interface SocialMediaErrordata {
    Media_Name: string;
    id: string;
    Media_Link: string;

  }

  interface Publications {
    id: string;
    Publications_Title:string;
    Publications_Description:string;
    Publications_Add_Link:string;
  }

  
  interface publicationsErrordata {
    Publications_Title:string;
    Publications_Description:string;
    Publications_Add_Link:string;
    id: string;
  
  }
  interface ProfessionalForms {
    Professional_Organizations:string;
    Professional_Description:string;
    Professional_Add_Link:string;
    id: string;
  }
  
  interface ProfessionalErrors {
    Professional_Organizations:string;
    Professional_Description:string;
    Professional_Add_Link:string;
  }
  interface LicenseForm {
    id: string;
    Seminars_Title:string;
    Seminars_Description:string;
    Seminars_Add_Link:string;
  }
  
  interface SeminarsErrors {
    Seminars_Title:string;
    Seminars_Description:string;
    Seminars_Add_Link:string;
  }


// Customizable Area End

interface S {
  // Customizable Area Start
  
  socialMediaForm: SocialMediaForm[];
  socialMediaErrordata: SocialMediaErrordata[];
  publicationsForm: Publications[];
  publicationserrors: publicationsErrordata[];
  professionalForms: ProfessionalForms[];
  professionalerrors: ProfessionalErrors[];
  seminarsForms: LicenseForm[];
  seminarsErrors: SeminarsErrors[];
  professionalDetails : ProfessionalDetails[];
  userId: string;
  isChange: boolean;
  authToken:string
  userID:number |null;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class AchievementsCustomFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiExperienceID:string ="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      professionalDetails: [],
     
  socialMediaForm: [{ 

    Media_Name: '',
    id: '',
    Media_Link: '',
  
   }],
  socialMediaErrordata: [],

  publicationsForm: [{ id: '1',  Publications_Title:'', Publications_Description:'',
 Publications_Add_Link:'', }],
  publicationserrors: [],

  professionalForms: [
    { id: '1',  Professional_Organizations:'',Professional_Description:'',Professional_Add_Link:'',},
  ],
  professionalerrors: [
    { Professional_Organizations:'',Professional_Description:'',Professional_Add_Link:'', },
  ],

  seminarsForms: [
    {
      id: '1',
      Seminars_Title:'',
      Seminars_Description:"",
      Seminars_Add_Link:"",
    },
  ],
  seminarsErrors: [
    {
        Seminars_Title:'',
      Seminars_Description:"",
      Seminars_Add_Link:"",
    },
  ],
      userId: "",
      isChange: false,
      authToken:"",
      userID:null

      // Customizable Area End
    };

    // Customizable Area Start

    

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.status === 500) {
          toast.error("Something went wrong!");
        } else if (responseJson.errors) {
          toast.error(responseJson.errors);
        }
        if (apiRequestCallId === this.apiExperienceID) {
          this.setState({ professionalDetails: responseJson });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.setState({
      userID: await storage.get('accountId'),
      authToken: await storage.get('authToken'),
    });
  }
  handleNext() {

    const educationIsValid = this.validatePublicationsForm();
    const certificateIsValid = this.validateprofessionalForms();
    const licenseIsValid = this.validateSeminarsForms();
    const experienceIsValid = this.validateSocialMediaForms();

    if ( educationIsValid && certificateIsValid && licenseIsValid && experienceIsValid) {
     
  
      this.handleProfesstionAndExperienceApi(); 

       this.props.navigation.navigate( '');

    } else {
      console.log('Validation failed! Please fix the errors.');
    }
  }

  handlebackpage(){    
    this.props.navigation.navigate("PatentsForm");
  }
  

  handlePublicationschnage = (
    e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>,
    id: string,
    field: string
  ) => {
    const { value } = e.target;

    const updatedPublicationsForm = this.state.publicationsForm.map(form =>
      form.id === id ? { ...form, [field]: value } : form
    );

    const updatedErrors = this.state.publicationserrors.map((error: any, index) => {
      const form = this.state.publicationsForm[index];
      if (form && form.id === id) {
        return {
          ...error,
          [field]: value ? "" : `The ${field} field is required`,
        };
      }
      return error; 
    });
    

    this.setState({ publicationsForm: updatedPublicationsForm, publicationserrors: updatedErrors });
  };

  handleAddPublications = () => {
    this.setState(prevState => ({
      publicationsForm: [
        ...prevState.publicationsForm,
        { id: (prevState.publicationsForm.length + 1).toString(),  Publications_Title:'', Publications_Description:'', Publications_Add_Link:'',}
      ],
      publicationserrors: [
        ...prevState.publicationserrors,
        { id: (prevState.publicationsForm.length + 1).toString(),  Publications_Title:'', Publications_Description:'', Publications_Add_Link:'',}
      ]
    }));
    this.handleProfesstionAndExperienceApi()
  };

  handleRemovePublications = (id: string) => {
    this.setState(prevState => ({
      publicationsForm: prevState.publicationsForm.filter(form => form.id !== id),
      publicationserrors: prevState.publicationserrors.filter(error => error.id !== id),
    }));
  };

  validatePublicationsForm = () => {
    let isValid = true;
    const errors = this.state.publicationsForm.map(() => ({
        Publications_Title:'', Publications_Description:'', Publications_Add_Link:'',
      id: ''
    }));

    this.state.publicationsForm.forEach((form, index) => {
      const formErrors = errors[index];

      if (!form.Publications_Title) {
        isValid = false;
        formErrors.Publications_Title = 'Title is required';
      }

      if (!form.Publications_Description) {
        isValid = false;
        formErrors.Publications_Description = 'Description name is required';
      }

      if (!form.Publications_Add_Link) {
        isValid = false;
        formErrors.Publications_Add_Link = 'Add Link is required';
      }

    
    });

    this.setState({ publicationserrors: errors });
    return isValid;
  };
handleAddprofessionalForms = () => {
  this.setState(prevState => ({
    professionalForms: [
      ...prevState.professionalForms,
      { id: (prevState.professionalForms.length + 1).toString(), Professional_Organizations:'',Professional_Description:'',Professional_Add_Link:'', }
    ],
    professionalerrors: [
      ...prevState.professionalerrors,
      { Professional_Organizations:'',Professional_Description:'',Professional_Add_Link:'', }
    ]
  }));
  this.handleProfesstionAndExperienceApi()
};

handleRemoveprofessionalForms = (id: string) => {
  this.setState(prevState => ({
    professionalForms: prevState.professionalForms.filter(form => form.id !== id),
    professionalerrors: prevState.professionalerrors.filter((_, index) => prevState.professionalForms[index].id !== id),
  }));
};

handlecertificateChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>, id: string, field: string) => {
  const { value } = e.target;

  const updatedProfessionalForms = this.state.professionalForms.map(form =>
    form.id === id ? { ...form, [field]: value } : form
  );

  const updatedErrors = this.state.professionalerrors.map((error: any, index) => {
    if (this.state.professionalForms[index].id === id) {
      return {
        ...error,
        [field]: value ? "" : `${field} is required`,
      };
    }
    return error;
  });

  this.setState({
    professionalForms: updatedProfessionalForms,
    professionalerrors: updatedErrors
  });
};

validateprofessionalForms = () => {
  let isValid = true;
  const errors: ProfessionalErrors[] = this.state.professionalForms.map(() => ({
    Professional_Organizations:'',Professional_Description:'',Professional_Add_Link:'',
  }));

  this.state.professionalForms.forEach((form, index) => {
    const formErrors = errors[index];

    if (!form.Professional_Organizations) {
      isValid = false;
      formErrors.Professional_Organizations = 'Professional_Organizations name is required';
    }

    if (!form.Professional_Description) {
      isValid = false;
      formErrors.Professional_Description = 'Description provider is required';
    }

    if (!form.Professional_Add_Link) {
      isValid = false;
      formErrors.Professional_Add_Link = 'Add_Link issue date is required';
    }

  });

  this.setState({ professionalerrors: errors });
  return isValid;
};

handleAddLicenseForm = () => {
  this.setState(prevState => ({
    seminarsForms: [
      ...prevState.seminarsForms,
      {
        id: (prevState.seminarsForms.length + 1).toString(),
        Seminars_Title:'',
        Seminars_Description:"",
        Seminars_Add_Link:"",
      },
    ],
    seminarsErrors: [
      ...prevState.seminarsErrors,
      {
        Seminars_Title:'',
        Seminars_Description:"",
        Seminars_Add_Link:"",
      },
    ],
  }));
  this.handleProfesstionAndExperienceApi()
};

handleRemoveLicenseForm = (id: string) => {
  this.setState(prevState => ({
    seminarsForms: prevState.seminarsForms.filter(form => form.id !== id),
    seminarsErrors: prevState.seminarsErrors.filter((_, index) => prevState.seminarsForms[index].id !== id),

  }));
};

handleLicenseChange = (
  e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>,
  id: string,
  field: string
) => {
  const { value } = e.target;
  const updatedSeminarsForms = this.state.seminarsForms.map(form =>
    form.id === id ? { ...form, [field]: value } : form
  );

  const updatedErrors = this.state.seminarsErrors.map((error, index) => {
    if (this.state.seminarsForms[index].id === id) {
      return {
        ...error,
        [field]: value ? "" : `${field} is required`,

      };
    }
    return error;
  });

  this.setState({ seminarsForms: updatedSeminarsForms, seminarsErrors: updatedErrors });
};

validateSeminarsForms = () => {
  let isValid = true;
  const errors: SeminarsErrors[] = this.state.seminarsForms.map(() => ({
    Seminars_Title:'',
    Seminars_Description:"",
    Seminars_Add_Link:"",
  }));

  this.state.seminarsForms.forEach((form, index) => {
    const formErrors = errors[index];

    if (!form.Seminars_Title) {
      isValid = false;
      formErrors.Seminars_Title = 'Title Profession is required';
    }

    if (!form.Seminars_Description) {
      isValid = false;
      formErrors.Seminars_Description = 'Description is required';
    }

    if (!form.Seminars_Add_Link) {
      isValid = false;
      formErrors.Seminars_Add_Link = 'Add_Link is required';
    }
  });

  this.setState({ seminarsErrors: errors });
  return isValid;
};
handleSocialMediaChange = (
  e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>,
  id: string,
  field: string
) => {
  const { value } = e.target;

  const updatedSocialMediaForms = this.state.socialMediaForm.map((form) =>
    form.id === id ? { ...form, [field]: value } : form
  );

  const updatedErrors = this.state.socialMediaErrordata.map((error: any, index) => {
    if (this.state.socialMediaForm[index].id === id) {
      return {
        ...error,
        [field]: value ? "" : `The ${field} field is required`,
      };
    }
    return error;
  });

  this.setState({
    socialMediaForm: updatedSocialMediaForms,
    socialMediaErrordata: updatedErrors,
  });
};
handleAddSocialMediaForm = () => {
  this.setState((prevState) => ({
    socialMediaForm: [
      ...prevState.socialMediaForm,
      {
        id: (prevState.socialMediaForm.length + 1).toString(),
        Media_Name: '',
        Media_Link: '',
        
      },
    ],
    socialMediaErrordata: [
      ...prevState.socialMediaErrordata,
      {
        id: (prevState.socialMediaForm.length + 1).toString(),
        Media_Name: '',
        Media_Link: '',
      },
    ],
  }));
  this.handleProfesstionAndExperienceApi()
};

handleRemoveSocialMediaForm = (id: string) => {
  this.setState((prevState) => ({
    socialMediaForm: prevState.socialMediaForm.filter((form) => form.id !== id),
    socialMediaErrordata: prevState.socialMediaErrordata.filter(
      (error) => error.id !== id
    ),
  }));
};

validateSocialMediaForms = () => {
  let isValid = true;
  const errors = this.state.socialMediaForm.map(() => ({
    Media_Name: '',
    Media_Link: '',
    id: "",
  }));

  this.state.socialMediaForm.forEach((form, index) => {
    const formErrors = errors[index];

    if (!form.Media_Name) {
      isValid = false;
      formErrors.Media_Name = "Media Name is required";
    }

    if (!form.Media_Link) {
      isValid = false;
      formErrors.Media_Link = "Media Link name is required";
    }


  
  });

  this.setState({ socialMediaErrordata: errors });
  return isValid;
};

handleProfesstionAndExperienceApi = () => {
  const header = {
    redirect: 'follow',
    "token": this.state.authToken
  };
  
  let formdata = new FormData();
  
  this.state.socialMediaForm.forEach((media) => {
    formdata.append(`account[social_media_and_websites_attributes][0][media_name]`, media.Media_Name);
    formdata.append(`account[social_media_and_websites_attributes][0][link]`, media.Media_Link);
  });


  this.state.publicationsForm.forEach((publication) => {
    formdata.append(`account[publications_and_contributions_attributes][0][title]`, publication.Publications_Title);
    formdata.append(`account[publications_and_contributions_attributes][0][description]`, publication.Publications_Description);
    formdata.append(`account[publications_and_contributions_attributes][0][link]`, publication.Publications_Add_Link);
  });


  this.state.professionalForms.forEach((Professional) => {
    formdata.append(`account[professional_affiliations_attributes][0][organization_name]`, Professional.Professional_Organizations);
    formdata.append(`account[professional_affiliations_attributes][0][description]`, Professional.Professional_Description);
    formdata.append(`account[professional_affiliations_attributes][0][link]`, Professional.Professional_Add_Link);
  });


  this.state.seminarsForms.forEach((Seminars) => {
    formdata.append(`account[engagements_and_seminars_attributes][0][title]`, Seminars.Seminars_Title);
    formdata.append(`account[engagements_and_seminars_attributes][0][description]`, Seminars.Seminars_Description);
    formdata.append(`account[engagements_and_seminars_attributes][0][link]`, Seminars.Seminars_Add_Link);
  
  });

  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.apiExperienceID = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `account_block/freelancers/${this.state.userID}/freelancer_profile_achievements`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formdata
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "PATCH"
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  
  return true;
};


  // Customizable Area End
}