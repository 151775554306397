import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from 'framework/src/StorageProvider';
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
export interface BasicDetailsData {
  data: {
    id: string,
    type: string,
    attributes: {
        id: 13,
        first_name: string,
        last_name: string,
        email: string,
        country_code: 91,
        full_phone_number: null,
        phone_number: 9885674763,
        dob: string,
        address: string,
        additional_address: string,
        apt_suite: string,
        nation: {
            id: 1,
            name: string
        },
        state: {
            id: 1,
            name: string,
            nation_id: 1
        },
        city: {
            id: 1,
            name: string,
            state_id: 9
        },
        zipcode: string,
        profile_status: string,
        job_title: string,
        job_description: string,
        skills: any[],
        title_description: null,
        price: null,
        work_history: null,
        profile_image: null,
        created_at: string,
        updated_at: string
    }
}
}
export interface Touched {
  firstName: boolean;
  lastName: boolean;
  phoneNumber: boolean;
  email: boolean;
  organization: boolean;
  teamName: boolean;
  userType: boolean;
  rating: boolean;
  gender: boolean;
  address: boolean;
  country: boolean;
  state: boolean;
  city: boolean;
}

export interface Error {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
}

export interface ResponseJson {
  id: string;
  attributes: {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    organization: string;
    team_name: string;
    i_am: string;
    stars_rating: string;
    gender: string;
    address: string;
    country: string;
    state: string;
    city: string;
    file: {
      file_name: string;
    }
  }
}

export interface Dropdown {
  label: string;
  value: string;
}

export interface Data {
  userId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  selectedFile: string;
  freelancerName:string
  freelancerEmail:string
  dateOfBirth: any
  streetAddress:string
  suite:string
  additionalAddress:string
  country: string,
  city: string,
  state: string,
  zipCode: string,
  phone: string,
  jobTitle: string,
  skills: string,
  description: string,
  dateOfBirthFreelancerError:string
  streetAddressError:string
  countryFreelancerErr:string
  cityError:string
  jobTitleError:string
  phoneError:string
  skillsError:string
  descriptionError:string
  zipCodeError:string
  stateError:string
  authToken:string
  fileInputRef:any;
  userID:number |null;
  basicDetailsData : BasicDetailsData;
  skillsArray: string []
  countryList: {
    id: number,
    name: string,
    code: string,
}[],
  stateList:{
      id: number,
      name: string
  }[]
  selectedCountryList:{
    id: number,
    name: string,
    code: string,
  }| undefined 
  profileImage:any;
  imageUploadError:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiBasicDetailsID: string = "";
  countryListID:string = "";
  stateListID:string="";
  updateProfileImageId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedFile:"",
      freelancerName:"",
      freelancerEmail:"",
      dateOfBirth: "",
      streetAddress:"",
      suite:"",
      additionalAddress:"",
      country: "",
      city: "",
      state: "",
      zipCode: "",
      phone: "",
      jobTitle: "",
      skills: "",
      description: "",
      dateOfBirthFreelancerError:"",
      streetAddressError:"",
      countryFreelancerErr:"",
      cityError:"",
      jobTitleError:"",
      phoneError:"",
      skillsError:"",
      descriptionError:"",
      zipCodeError:"",
      stateError:"",
      authToken:"",
      userID: null,
      skillsArray:  [],
      fileInputRef:React.createRef(),
      basicDetailsData : {
        "data": {
            "id": "13",
            "type": "freelancer_profile_basic_details",
            "attributes": {
                "id": 13,
                "first_name": "seem",
                "last_name": "jet",
                "email": "seem@mailinator.com",
                "country_code": 91,
                "full_phone_number": null,
                "phone_number": 9885674763,
                "dob": "2010-05-12",
                "address": "ABCd G",
                "additional_address": "ST Apt",
                "apt_suite": "345",
                "nation": {
                    "id": 1,
                    "name": "Andorra"
                },
                "state": {
                    "id": 1,
                    "name": "Canillo",
                    "nation_id": 1
                },
                "city": {
                    "id": 1,
                    "name": "Aguila",
                    "state_id": 9
                },
                "zipcode": "110990",
                "profile_status": "incomplete",
                "job_title": "Project manager",
                "job_description": "job_description",
                "skills": [
                    "ROR",
                    "Java\n",
                    "Test"
                ],
                "title_description": null,
                "price": null,
                "work_history": null,
                "profile_image": null,
                "created_at": "2024-07-01T07:49:21.359Z",
                "updated_at": "2024-09-10T07:37:33.073Z"
            }
        }
    },
    countryList: [],
    stateList:[],
    selectedCountryList: undefined,
    profileImage:"",
    imageUploadError:"",
      // Customizable Area End
    };

    // Customizable Area Start
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleStreetAdressChange = this.handleStreetAdressChange.bind(this)
    this.handleSuiteChange = this.handleSuiteChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleZipCodeChange = this.handleZipCodeChange.bind(this)
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleJobTitleChange = this.handleJobTitleChange.bind(this);
    this.handleSkillsChange = this.handleSkillsChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if (webResponseJson) {
        if (webApiRequestCallId === this.apiBasicDetailsID) {
          this.setState({ basicDetailsData: webResponseJson });
        }
        if (webApiRequestCallId === this.countryListID) {
          if (webResponseJson) {
            const countries = webResponseJson.map((country: any) => ({
              id: country.id,
              name: country.name,
              code: country.code,
            }));
            this.setState({ countryList: countries });
          } 
        }
        if (webApiRequestCallId === this.stateListID) {
          const states = webResponseJson.map((state: any) => ({
            id: state.id,
            name: state.name,
          }));
          this.setState({ stateList: states });
        }
        if(this.updateProfileImageId === webApiRequestCallId ){
            this.setState({
              profileImage: this.state.basicDetailsData.data.attributes.profile_image,
            });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.setState({
      userID: await storage.get('accountId'),
      authToken: await storage.get('authToken'),
      freelancerName: await storage.get('accountHolderName'),
      freelancerEmail: await storage.get('UserEmail')
    });
    this.getCountriesList()
  }
  validateForm = () => {
    const { 
      dateOfBirth,
      streetAddress,
      country,
      city,
      state,
      phone,
      jobTitle,
      skillsArray,
      description,
    } = this.state;
  
    let isValid = true;
  
    if (!dateOfBirth) {
      this.setState({ dateOfBirthFreelancerError: "* Date of birth is required" });
      isValid = false;
    } else {
      this.setState({ dateOfBirthFreelancerError: "" });
    }
  
    if (!streetAddress) {
      this.setState({ streetAddressError: "* Street address is required" });
      isValid = false;
    } else {
      this.setState({ streetAddressError: "" });
    }
  
    if (!country) {
      this.setState({ countryFreelancerErr: "* Country is required" });
      isValid = false;
    } else {
      this.setState({ countryFreelancerErr: "" });
    }
  
    if (!city) {
      this.setState({ cityError: "* City is required" });
      isValid = false;
    } else {
      this.setState({ cityError: "" });
    }
  
    if (!phone) {
      this.setState({ phoneError: "* Phone number is required" });
      isValid = false;
    } else {
      this.setState({ phoneError: "" });
    }
  
    if (!jobTitle) {
      this.setState({ jobTitleError: "* Job Title is required" });
      isValid = false;
    } else {
      this.setState({ jobTitleError: "" });
    }
  
    if (!skillsArray.length) {
      this.setState({ skillsError: "* Skills are required" });
      isValid = false;
    } else {
      this.setState({ skillsError: "" });
    }
  
    if (!description) {
      this.setState({ descriptionError: "* Description is required" });
      isValid = false;
    } else {
      this.setState({ descriptionError: "" });
    }

    if (!state) {
      this.setState({ stateError: "* State is required" });
      isValid = false;
    } else {
      this.setState({ stateError: "" });
    }

    return isValid;
  };
  
  handleNext = () => {
    if (this.validateForm()) {
      this.setState({
        dateOfBirthFreelancerError:"",
        streetAddressError:"",
        countryFreelancerErr:"",
        cityError:"",
        jobTitleError:"",
        phoneError:"",
        skillsError:"",
        descriptionError:"",
        stateError:"",
      });
      this.handleBasicDetailsApi()
      this.props.navigation.navigate( 'CustomFormExperience');

  
    }
  };
  handleDateChange(event: { target: { value: any; }; }) {
    this.setState({ dateOfBirth: event.target.value, 
      dateOfBirthFreelancerError: "",
     });
  }
  handleStreetAdressChange(event: { target: { value: any; }; }){
    this.setState({streetAddress:event.target.value,
      streetAddressError: "",
    })
  }
  handleSuiteChange(event: { target: { value: any; }; }) {
    this.setState({ suite: event.target.value});
  }

  handleAddressChange(event: { target: { value: any; }; }) {
    this.setState({ additionalAddress: event.target.value,
      streetAddressError: "",
     });
  }


handleCountryChange(event: { target: { value: any }; }) {
  const selectedCountryName = event.target.value;

  const selectedCountry = this.state.countryList.find(
      (country) => country.name === selectedCountryName
  );

  this.setState({
      country: selectedCountryName,
      selectedCountryList: selectedCountry,
      countryFreelancerErr: ""
  }, () => {
      if (selectedCountry) {
          this.getStateList(selectedCountry.id);
      }
  });
}

  handleCityChange(event: { target: { value: any; }; }) {
    this.setState({ city: event.target.value,
      cityError: "",
     });
  }

  handleStateChange(event: { target: { value: any; }; }) {
    this.setState({ 
        state: event.target.value,
        stateError: "",
    });
}

  handlePhoneChange = (event: { target: { value: string; }; }) => {
    const phone = event.target.value;
    this.setState({ 
      phone,
      phoneError: this.validatePhone(phone) ? "" : "* Invalid phone number format"
    });
  };
  
  handleZipCodeChange = (event: { target: { value: string; }; }) => {
    const zipCode = event.target.value;
    this.setState({ 
      zipCode,
      zipCodeError: this.validateZipCode(zipCode) ? "" : "* Invalid zip code format"
    });
  };
  
  validatePhone = (phone: string) => {
    const phoneRegex = /^[0-9]{10}$/; 
    return phoneRegex.test(phone);
  };
  
  validateZipCode = (zipCode: string) => {
    const zipCodeRegex = /^[0-9]{6}$/; 
    return zipCodeRegex.test(zipCode);
  };
  

  handleJobTitleChange(event: { target: { value: any; }; }) {
    this.setState({ jobTitle: event.target.value,
      jobTitleError: "",
     });
  }

  handleSkillsChange(event: { target: { value: any; }; }) {
    this.setState({ skills: event.target.value,
      skillsError: "",
     });
  }

  handleDescriptionChange(event: { target: { value: any; }; }) {
    this.setState({ description: event.target.value,
      descriptionError:""
     });
  }
  handleSkillsKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && this.state.skills.trim() !== '') {
      this.setState((prevState) => ({
        skillsArray: [...prevState.skillsArray, prevState.skills.trim()],
        skills: '',
        skillsError:""
      }));
    }
  };
  handleDeleteSkill = (skillToDelete: string) => {
    this.setState((prevState) => ({
      skillsArray: prevState.skillsArray.filter((skill) => skill !== skillToDelete),
    }));
  };
  
  handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
      if (allowedTypes.includes(file.type)) {
        this.setState({ profileImage: URL.createObjectURL(file), selectedFile: file, imageUploadError: '' });
      } else {
        const imageUploadError = 'Profile image: You are not allowed to upload "' + file.type + '" files, allowed types: jpg, jpeg, png';
        this.setState({ imageUploadError });
      }
    }
  }
  handleEditClick = () => {
    const fileInput = this.state.fileInputRef.current;
    if (fileInput) {
      fileInput.click();
    }
    this.handleUpdateProfileImage(this.state.selectedFile);
  }
  
  
  handleBasicDetailsApi = () => {
    const header = {
      redirect: 'follow',
      "token": this.state.authToken
    };
    let formdata = new FormData();
    formdata.append("account[dob]", this.state.dateOfBirth);
    formdata.append("account[address]", this.state.streetAddress);
    formdata.append("account[additional_address]", this.state.additionalAddress);
    formdata.append("account[apt_suite]", this.state.suite);
    if (this.state.selectedCountryList?.id) {
      formdata.append("account[nation_id]", String(this.state.selectedCountryList.id));
    }
    formdata.append("account[state_id]", this.state.state); 
    formdata.append("account[city]",this.state.city)
    formdata.append("account[zipcode]",this.state.zipCode)
    formdata.append("account[phone_number]",this.state.phone)
    formdata.append("account[job_title]",this.state.jobTitle)
    this.state.skillsArray.forEach(skill => {
      formdata.append("account[skills][]", skill);
    });    
    formdata.append("account[job_description]",this.state.description)
    if (this.state.selectedFile) {
      formdata.append('account[profile_image]', this.state.selectedFile); 
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiBasicDetailsID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/freelancers/${this.state.userID}/freelancer_profile_basic_details`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  };

  getCountriesList = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.authToken
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.countryListID = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/nations`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getStateList = async (stateValue: number|null) => {
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.authToken
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.stateListID = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_posts/nations/get_states?id=${stateValue}`
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
};

handleUpdateProfileImage = async (updatedIMG: any) => {
  const updateProfileImage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  const header = {
    token: this.state.authToken
  };
  // Create a new FormData object
  const formdata = new FormData();
  formdata.append("[data][attributes][profile_image]", updatedIMG);
  
  this.updateProfileImageId = updateProfileImage.messageId;
  updateProfileImage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `account_block/accounts/${this.state.userID}/update_freelancer_profile_image`
  );
  updateProfileImage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  updateProfileImage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formdata
  )
  updateProfileImage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.updateUserMethodeType
  );
  runEngine.sendMessage(updateProfileImage.id, updateProfileImage);
}

  // Customizable Area End
}