// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  Grid,
  FormControl,
  OutlinedInput,

} from "@material-ui/core";
import { fifth_steper } from "./assets";
import { addBtn } from "./assets";


// Customizable Area End

// Customizable Area Start
import FreelancerNavBar from "../../dashboard/src/FreelancerNavBar.web";
import AchievementsCustomFormController, {
  Props,
 
} from "./AchievementsCustomFormController.web";
import FooterWeb from "../../dashboard/src/Components/Footer.web";
// Customizable Area End

export default class AchievementsCustomForm extends AchievementsCustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleNext = this.handleNext.bind(this);


    // Customizable Area End
  }

  // Customizable Area Start
 
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
     
      <>
        <FreelancerNavBar navigation={undefined} />
      
        <Box style={{ padding: "50px 20px 26px 20px" }}>
          <img
            src={fifth_steper}
            style={{
              width: "100%",
              height: "auto",
              maxWidth: "1500px",
              margin: "0 auto",
              display: "block",
            }}
          />
        </Box>
        <Box style={webStyle.mainBox}>
          <Typography style={webStyle.basicDetailsHead}>
          Add your Social Media and Professional Websites
          </Typography>
          <Typography style={webStyle.main_subheading}>
          Provide links to your professional social media profiles and websites.
          </Typography>
          <Box>
  {this.state.socialMediaForm.map((form, index) => (
    <Box key={form.id}>
      <Box style={webStyle.BoxTwoStyle}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography style={webStyle.labelStyle}>Media Name</Typography>
                <FormControl style={webStyle.textField} variant="outlined">
                  <OutlinedInput
                    data-test-id="Media_Name"
                    placeholder="Personal Website"
                    value={form.Media_Name}
                    onChange={(e) => this.handleSocialMediaChange(e, form.id, "Media_Name")}
                    error={!!this.state.socialMediaErrordata[index]?.Media_Name}
                   

                  />
                  {this.state.socialMediaErrordata[index]?.Media_Name && (
                    <Typography color="error">
                      {this.state.socialMediaErrordata[index].Media_Name}
                    </Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography style={webStyle.labelStyle}>Link</Typography>
                <FormControl style={webStyle.textField} variant="outlined">
                  <OutlinedInput
                    placeholder="www.example.com"
                    value={form.Media_Link}
                    onChange={(e) => this.handleSocialMediaChange(e, form.id, "Media_Link")}
                    error={!!this.state.socialMediaErrordata[index]?.Media_Link}
                    data-test-id="Media_Link"

                  />
                  {this.state.socialMediaErrordata[index]?.Media_Link && (
                    <Typography color="error">
                      {this.state.socialMediaErrordata[index].Media_Link}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {index === this.state.socialMediaForm.length - 1 && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ marginTop: "26px" }}
        >
          <Grid item>
            {this.state.socialMediaForm.length < 5 && (
              <Button
                variant="text"
                style={webStyle.removeText}
                onClick={this.handleAddSocialMediaForm}
                data-test-id="SocialMediaForm_add"
              >
                  <Box style={{ padding: "0 14px 3px 0" }}>
                       <img  src={addBtn}
                        style={{width: "100%",  height: "34",maxWidth: "150px",  margin: "0 auto",  display: "block",  }} />
                  </Box>
                  Add another 
              </Button>
            )}
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                {this.state.socialMediaForm.length > 1 && (
                  <Button
                    variant="text"
                    style={webStyle.removeText}
                    onClick={() => this.handleRemoveSocialMediaForm(form.id)}
                     data-test-id="SocialMediaForm_remove"

                  >
                    Remove
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  ))}
</Box>



<Box style={webStyle.mainSubBox}>
        <Typography style={webStyle.basicDetailsHead}>Add your Publications and Contributions</Typography>
        <Typography style={webStyle.main_subheading}>
        Provide links to published articles, press releases, or blogs that feature your work or contributions.
        </Typography>
        {this.state.publicationsForm.map((form, index) => (
          <Box key={form.id}>
            <Box style={webStyle.BoxTwoStyle}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Typography style={webStyle.labelStyle}>Title</Typography>
                  <FormControl style={webStyle.textField} variant="outlined">
                    <OutlinedInput
                      placeholder="Enter Title here"
                      value={form.Publications_Title}
                      onChange={(e) => this.handlePublicationschnage(e, form.id, 'Publications_Title')}
                      error={!!this.state.publicationserrors[index]?.Publications_Title}
                     data-test-id="Publications_Title"
                    />
                    {this.state.publicationserrors[index]?.Publications_Title && (
                      <Typography color="error">{this.state.publicationserrors[index].Publications_Title}</Typography>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Typography style={webStyle.labelStyle}>Description</Typography>
                  <FormControl style={webStyle.textField} variant="outlined">
                    <OutlinedInput
                      placeholder="Enter Description here"
                      value={form.Publications_Description}
                      onChange={(e) => this.handlePublicationschnage(e, form.id, 'Publications_Description')}
                      error={!!this.state.publicationserrors[index]?.Publications_Description}
                     data-test-id="Publications_Description"

                    />
                    {this.state.publicationserrors[index]?.Publications_Description && (
                      <Typography color="error">{this.state.publicationserrors[index].Publications_Description}</Typography>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Typography style={webStyle.labelStyle}>Add Link</Typography>
                  <FormControl style={webStyle.textField} variant="outlined">
                    <OutlinedInput
                      placeholder="Enter Link here"
                      value={form.Publications_Add_Link}
                      onChange={(e) => this.handlePublicationschnage(e, form.id, 'Publications_Add_Link')}
                      error={!!this.state.publicationserrors[index]?.Publications_Add_Link}
                     data-test-id="Publications_Add_Link"

                    />
                    {this.state.publicationserrors[index]?.Publications_Add_Link && (
                      <Typography color="error">{this.state.publicationserrors[index].Publications_Add_Link}</Typography>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: "26px" }}>
              <Grid item>
                {this.state.publicationsForm.length < 5 && (
                  <Button
                    variant="text"
                    style={webStyle.removeText}
                    onClick={this.handleAddPublications}
                    data-test-id='educationAddBTN'
                  >
                    <Box style={{ padding: "0 14px 3px 0" }}>
                       <img  src={addBtn}
                        style={{width: "100%",  height: "34",maxWidth: "150px",  margin: "0 auto",  display: "block",  }} />
                  </Box>
                  Add another 
                  </Button>
                )}
              </Grid>
              <Grid item>
                {this.state.publicationsForm.length > 1 && (
                  <Button
                    variant="text"
                    style={webStyle.removeText}
                    onClick={() => this.handleRemovePublications(form.id)}
                    data-test-id="Publications_remove"
                  >
                    Remove
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>


      <Box style={webStyle.mainSubBox}>
        <Typography style={webStyle.basicDetailsHead}>Add your Professional Affiliations</Typography>
        <Typography style={webStyle.main_subheading}>
        List any professional organizations or industry groups you are a member of. </Typography>
        <Box>
          {this.state.professionalForms.map((form, index) => (
            <Box key={form.id}>
              <Box style={webStyle.BoxTwoStyle}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <Typography style={webStyle.labelStyle}>Add Organizations or Industry Groups</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <OutlinedInput
                        placeholder="Enter Organization or Industry Groups here"
                        value={form.Professional_Organizations}
                        onChange={(e) => this.handlecertificateChange(e, form.id, 'Professional_Organizations')}
                        error={!!this.state.professionalerrors[index]?.Professional_Organizations}
                        data-test-id='Professional_Organizations'
                      />
                        {this.state.professionalerrors[index]?.Professional_Organizations && (
                      <Typography color="error">{this.state.professionalerrors[index].Professional_Organizations}</Typography>
                    )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography style={webStyle.labelStyle}>Description</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <OutlinedInput
                        placeholder="Enter Description here"
                        value={form.Professional_Description}
                        onChange={(e) => this.handlecertificateChange(e, form.id, 'Professional_Description')}
                        error={!!this.state.professionalerrors[index]?.Professional_Description}
                        data-test-id='Professional_Description'

                      />
                         {this.state.professionalerrors[index]?.Professional_Description && (
                      <Typography color="error">{this.state.professionalerrors[index].Professional_Description}</Typography>
                    )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography style={webStyle.labelStyle}>Add Link</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <OutlinedInput
                        placeholder="Enter Link here"
                        value={form.Professional_Add_Link}
                        onChange={(e) => this.handlecertificateChange(e, form.id, 'Professional_Add_Link')}
                        error={!!this.state.professionalerrors[index]?.Professional_Add_Link}
                        data-test-id='Professional_Add_Link'

                      />
                        {this.state.professionalerrors[index]?.Professional_Add_Link && (
                      <Typography color="error">{this.state.professionalerrors[index].Professional_Add_Link}</Typography>
                    )}
                    </FormControl>
                  </Grid>
                
                </Grid>
              </Box>
              <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: '26px' }}>
                <Grid item>
                  {this.state.professionalForms.length < 5 && (
                    <Button variant="text" style={webStyle.removeText} onClick={this.handleAddprofessionalForms}>
                       <Box style={{ padding: "0 14px 3px 0" }}>
                       <img  src={addBtn}
                        style={{width: "100%",  height: "34",maxWidth: "150px",  margin: "0 auto",  display: "block",  }} />
                  </Box>
                  Add another 
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  {this.state.professionalForms.length > 1 && (
                    <Button variant="text" style={webStyle.removeText}
                     onClick={() => this.handleRemoveprofessionalForms(form.id)}
                     
                      data-test-id="handleRemoveprofessionalForms"
                     >
                      Remove
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          ))}
        </Box>
      </Box>

      <Box style={webStyle.mainSubBox}>
        <Typography style={webStyle.basicDetailsHead}>Add your Speaking Engagements and Seminars</Typography>
        <Typography style={webStyle.main_subheading}>List any recent speaking engagements or seminars where you have been a speaker or panelist.</Typography>
        <Box>
          {this.state.seminarsForms.map((form, index) => (
            <Box key={form.id}>
              <Box style={webStyle.BoxTwoStyle}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <Typography style={webStyle.labelStyle}>Title</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <OutlinedInput
                        placeholder="Enter Title here"
                        value={form.Seminars_Title}
                        onChange={(e) => this.handleLicenseChange(e, form.id, 'Seminars_Title')}
                        data-test-id='Seminars_Title'
                        error={!!this.state.seminarsErrors[index]?.Seminars_Title}
                      />
                      {this.state.seminarsErrors[index]?.Seminars_Title && (
                      <Typography color="error">{this.state.seminarsErrors[index].Seminars_Title}</Typography>
                    )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography style={webStyle.labelStyle}>Description</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <OutlinedInput
                        placeholder="Enter Description here"
                        value={form.Seminars_Description}
                        onChange={(e) => this.handleLicenseChange(e, form.id, 'Seminars_Description')}
                        data-test-id='Seminars_Description'

                        error={!!this.state.seminarsErrors[index]?.Seminars_Description}
                      />
                       {this.state.seminarsErrors[index]?.Seminars_Description && (
                      <Typography color="error">{this.state.seminarsErrors[index].Seminars_Description}</Typography>
                    )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography style={webStyle.labelStyle}>Add Link</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <OutlinedInput
                        placeholder="Enter Link here"
                        value={form.Seminars_Add_Link}
                        onChange={(e) => this.handleLicenseChange(e, form.id, 'Seminars_Add_Link')}
                        data-test-id='Seminars_Add_Link'

                        error={!!this.state.seminarsErrors[index]?.Seminars_Add_Link}
                      />
                         {this.state.seminarsErrors[index]?.Seminars_Add_Link && (
                      <Typography color="error">{this.state.seminarsErrors[index].Seminars_Add_Link}</Typography>
                    )}
                    </FormControl>
                  </Grid>
                
                </Grid>
              </Box>
              <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: '26px' }}>
                <Grid item>
                  {this.state.seminarsForms.length < 5 && (
                    <Button variant="text" style={webStyle.removeText} onClick={this.handleAddLicenseForm}>
                       <Box style={{ padding: "0 14px 3px 0" }}>
                       <img  src={addBtn}
                        style={{width: "100%",  height: "34",maxWidth: "150px",  margin: "0 auto",  display: "block",  }} />
                  </Box>
                  Add another 
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  {this.state.seminarsForms.length > 1 && (
                    <Button variant="text" style={webStyle.removeText} onClick={() => this.handleRemoveLicenseForm(form.id)}>
                      Remove
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          ))}
        </Box>
      </Box>

     





        <Grid container justifyContent="space-between" alignItems="center" style={{marginTop:"26px"}}>
      <Grid item>
        <Button variant="outlined" style={webStyle.backButton}
          onClick={this.handlebackpage.bind(this)}
          data-test-id="handlebackpage"
        >
          Back
        </Button>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
          <Button
           variant="text"
           style={webStyle.laterText}
         
        >
          I ll Do It Later
        </Button>
          </Grid>
          <Grid item>
          <Button
          variant="contained"
          style={webStyle.nextButton}
          onClick={this.handleNext}
        data-test-id="navidateTOnext"
        >
          FINISH
        </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
       


    </Box>
        <FooterWeb />
      </>
      // Customizable Area End
    );
  }

  
}

const webStyle = {
  mainBox:{
    padding:"56px"
  },
  mainSubBox:{
    marginTop:"28px"
  },
  basicDetailsHead:{
    fontFamily:"Rubik",
    fontSize:"36px",
    fontWeight:600,
  },
  main_subheading:{
    fontFamily:"Rubik",
    fontSize:"20px",
    fontWeight:300,
  },
  BoxTwoStyle:{
    border: "1px solid #B1B1B1",
    padding: "28px",
    borderRadius:"12px",
    marginTop:"20px"
  },
  imageBoxStyle:{
    display:"flex",
    gap :"48px"
  },
  nameStyle:{
    fontFamily:"Rubik",
    fontSize:"28px",
    fontweight:500,
    color:"#0A0A0A"
  },
  emailStyle:{
    fontFamily:"Rubik",
    fontSize:"18px",
    color:"#0A0A0A"
  },
  updateProfileBtn:{
    color: "#364BA0",
  border: "1px solid #364BA0",
  borderRadius: "26px",
  marginTop: "16px",
  display: "flex",
  justifyContent: "center",
  padding: "10px",
  fontSize: "20px",
  fontFamily:"Rubik",
  fontWeight: 500
  },
  labelStyle:{
    fontFamily:"Rubik",
    fontSize:"16px",
    color:"#0A0A0A",
    fontWeight:500,
    marginBottom:"8px"
  },
  withoutlabelStyle:{
    fontFamily:"Rubik",
    fontSize:"16px",
    color:"#0A0A0A",
    fontWeight:500,
    marginBottom:"8px",
paddingTop:"23px"
  },
  textField: {
    width: "100%",
    "&.MuiFormLabel-root.Mui-focused ": {
      color: "#6F6E6E !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ": {
      borderColor: "#6F6E6E !important",
      borderRadius: "12px !important"
    },
    "& .MuiOutlinedInput-root":{
      borderRadius: "12px !important"
    }
  },
   
  chipStyle: {
    background: "#364BA0",
    borderRadius: "26px",
    display: "flex",
    padding: "8px",
    gap: "6px",
    justifyContent: "center",
  },
  buttonStyle: {
    margin: '10px 0',
    fontWeight: 500,
  },
  backButton: {
    borderColor: '#364BA0',
    color: '#364BA0',
    height:"40px",
    padding:"22px",
    fontFamily:"Rubik",
    fontSize:"18px",
    fontWeight:500
  },
  nextButton: {
    backgroundColor: '364BA0',
    color: '#fff',
    height:"40px",
    padding:"22px",
    fontFamily:"Rubik",
    fontSize:"18px",
    fontWeight:500
  },
  iconStyle: {
    color: '#fff',  
    fontSize: '20px',  
  },
  laterText:{
    color: '#364BA0',  
    textTransform: 'uppercase' as 'uppercase',
    textDecoration: 'underline' as 'underline',
    fontFamily:"Rubik",
    fontSize:"18px",
    fontWeight:500
  },
  removeText:{
    color: '#364BA0',  
    textTransform: 'initial' as 'initial',
    fontFamily:"Rubik",
    fontSize:"18px",
    fontWeight:500
  }
};

// Customizable Area End